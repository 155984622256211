import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import PrivateRoute from './PrivateRoute';
import Home from './routes/home/Home';
import Login from './routes/login/Login';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Home}/>
      </Switch>
    </Router>
  );
}

export default App;
