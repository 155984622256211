import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
    const isAuthenticated = false;
    const {component: Component, ...rest} = props;
    return (
        <Route 
            {...rest} 
            render={(renderProps) => isAuthenticated ? (<Component {...renderProps}/>) : (<Redirect to="/login"/>) }/>
    );
}

export default PrivateRoute;