import React from 'react';

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false
        };
    }

    render() {
        return (
            <div>
                <p>Home</p>
            </div>
        );
    }

}


export default Home;
