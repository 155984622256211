import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { LogIn } from 'react-feather';
import './Login.css';


class Login extends React.Component {

    render() {
        return (
            <section className="hero is-success is-fullheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="column is-4 is-offset-4">
                            <h3 className="title has-text-black">Login</h3>
                            <hr className="login-hr"></hr>
                            <p className="subtitle has-text-black">Please login to proceed.</p>
                            <div className="box">
                                <form>
                                    <div className="field">
                                        <div className="control">
                                            <input className="input is-large" type="email" placeholder="Your Email" autofocus=""/>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control">
                                            <input className="input is-large" type="password" placeholder="Your Password"/>
                                        </div>
                                    </div>
                                    <button className="button is-block is-info is-large is-fullwidth">Login <LogIn aria-hidden="true"/></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;
